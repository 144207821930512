<template>
  <admin-page-card
    pageName="Single Click Defaults"
    iconClass="fa fa-sliders fa-lg"
  >
    <div class="single-click-default">
      <v-wait for="fetchingDetails">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>

        <div class="row">
          <div class="col-sm-6">
            <b-form-group
              label="Group"
              label-for="group"
              :label-cols="3"
              :horizontal="true"
              class=""
            >
              <multiselect
                v-model="selectedGroup"
                :options="groups"
                :multiple="false"
                :close-on-select="true"
                :showLabels="false"
                track-by="id"
                label="name"
                placeholder="Select Group"
                @close="getClientDefaults"
              >
              </multiselect>
            </b-form-group>
          </div>
        </div>

        <div :class="['py-3']" v-if="selectedGroup">
          <b-tabs v-model="tabIndex">
            <b-tab>
              <template slot="title">
                <h6 class="tab-title">FIN Reporter</h6>
              </template>
              <v-wait for="loadingClientDefaults">
                <template slot="waiting">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="20"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>
                <div class="pt-2">
                  <div class="row">
                    <div class="col-sm-9">
                      <b-form-group
                        label="Default Peer Group Category"
                        label-for="peer_group"
                        :label-cols="3"
                        :horizontal="true"
                        class=""
                      >
                        <multiselect
                          v-model="reporterSelectedPeer"
                          :options="defaultPeerOptions"
                          :multiple="false"
                          :close-on-select="true"
                          :showLabels="false"
                          track-by="id"
                          label="name"
                          placeholder="Select Peer Category"
                          @close="
                            updateDefault(
                              reporterSelectedPeer,
                              'peer_group_type',
                              'fin_reporter'
                            )
                          "
                        >
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-9">
                      <b-form-group
                        label="Default Bank Report"
                        label-for="bank_report"
                        :label-cols="3"
                        :horizontal="true"
                        class=""
                      >
                        <multiselect
                          v-model="reporterSelectedBankReport"
                          :options="bankNavigatorReports"
                          :multiple="false"
                          :close-on-select="true"
                          :showLabels="false"
                          track-by="id"
                          label="name"
                          placeholder="Select Bank Report"
                          @close="
                            updateDefault(
                              reporterSelectedBankReport,
                              'bank_report_id',
                              'fin_reporter'
                            )
                          "
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <span>
                              <span class="pr-1">{{ props.option.name }} </span>
                              <span :class="props.option.showShared">
                                <b-badge pill variant="secondary" class="px-1"
                                  >Shared By User:
                                  {{ props.option.user_id }}</b-badge
                                >
                              </span>
                            </span>
                          </template>

                          <template slot="option" slot-scope="props">
                            <span>
                              <span class="pr-1">{{ props.option.name }} </span>
                              <span :class="props.option.showShared">
                                <b-badge pill variant="secondary" class="px-1"
                                  >Shared By User:
                                  {{ props.option.user_id }}</b-badge
                                >
                              </span>
                            </span>
                          </template>
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-9">
                      <b-form-group
                        label="Default CU Report"
                        label-for="cu_report"
                        :label-cols="3"
                        :horizontal="true"
                        class=""
                      >
                        <multiselect
                          v-model="reporterSelectedCUReport"
                          :options="cuNavigatorReports"
                          :multiple="false"
                          :close-on-select="true"
                          :showLabels="false"
                          track-by="id"
                          label="name"
                          placeholder="Select CU Report"
                          @close="
                            updateDefault(
                              reporterSelectedCUReport,
                              'credit_union_report_id',
                              'fin_reporter'
                            )
                          "
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <span>
                              <span class="pr-1">{{ props.option.name }} </span>
                              <span :class="props.option.showShared">
                                <b-badge pill variant="secondary" class="px-1"
                                  >Shared By User:
                                  {{ props.option.user_id }}</b-badge
                                >
                              </span>
                            </span>
                          </template>

                          <template slot="option" slot-scope="props">
                            <span>
                              <span class="pr-1">{{ props.option.name }} </span>
                              <span :class="props.option.showShared">
                                <b-badge pill variant="secondary" class="px-1"
                                  >Shared By User:
                                  {{ props.option.user_id }}</b-badge
                                >
                              </span>
                            </span>
                          </template>
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-9">
                      <b-form-group
                        label="Default BHC Report"
                        label-for="bhc_report"
                        :label-cols="3"
                        :horizontal="true"
                        class=""
                      >
                        <multiselect
                          v-model="reporterSelectedBHCReport"
                          :options="bhcNavigatorReports"
                          :multiple="false"
                          :close-on-select="true"
                          :showLabels="false"
                          track-by="id"
                          label="name"
                          placeholder="Select BHC Report"
                          @close="
                            updateDefault(
                              reporterSelectedBHCReport,
                              'bhc_report_id',
                              'fin_reporter'
                            )
                          "
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <span>
                              <span class="pr-1">{{ props.option.name }} </span>
                              <span :class="props.option.showShared">
                                <b-badge pill variant="secondary" class="px-1"
                                  >Shared By User:
                                  {{ props.option.user_id }}</b-badge
                                >
                              </span>
                            </span>
                          </template>

                          <template slot="option" slot-scope="props">
                            <span>
                              <span class="pr-1">{{ props.option.name }} </span>
                              <span :class="props.option.showShared">
                                <b-badge pill variant="secondary" class="px-1"
                                  >Shared By User:
                                  {{ props.option.user_id }}</b-badge
                                >
                              </span>
                            </span>
                          </template>
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </v-wait>
            </b-tab>
          </b-tabs>
        </div>
      </v-wait>
    </div>
  </admin-page-card>
</template>

<script charset="utf-8">
// api
import singleClickAPI from '@/api/finapps/admin/single_click_defaults'
import selfServeReportsAPI from '@/api/finapps/self_serve_reports'
// ui components
import Multiselect from 'vue-multiselect'

export default {
  name: 'SingleClickDefaults',
  components: {
    Multiselect
  },
  created() {
    this.loadInitData()
  },
  data() {
    return {
      tabIndex: 0,
      groups: [],
      reporterReports: [],
      reportDefaults: [],
      selectedGroup: null,
      defaultPeerOptions: [
        {
          id: 'focus',
          name: 'Focus Group'
        },
        {
          id: 'assets',
          name: 'Asset Group'
        },
        {
          id: 'local',
          name: 'Market Group'
        }
      ],
      reporterSelectedPeer: null,
      reporterSelectedBankReport: null,
      reporterSelectedCUReport: null,
      reporterSelectedBHCReport: null
    }
  },
  computed: {
    reporterDefaults() {
      return this.reportDefaults.filter(d => d.report_type === 'fin_reporter')
    },
    bankNavigatorReports() {
      return this.reporterReports.filter(r => r.fi_type === 'bank')
    },
    cuNavigatorReports() {
      return this.reporterReports.filter(r => r.fi_type === 'credit_union')
    },
    bhcNavigatorReports() {
      return this.reporterReports.filter(r => r.fi_type === 'bhc')
    },
    reporterPeer() {
      return (
        this.reporterDefaults.find(
          rd => rd.default_attr === 'peer_group_type'
        ) || {}
      )
    },
    reporterBankReport() {
      return (
        this.reporterDefaults.find(
          rd => rd.default_attr === 'bank_report_id'
        ) || {}
      )
    },
    reporterCUReport() {
      return (
        this.reporterDefaults.find(
          rd => rd.default_attr === 'credit_union_report_id'
        ) || {}
      )
    },
    reporterBHCReport() {
      return (
        this.reporterDefaults.find(rd => rd.default_attr === 'bhc_report_id') ||
        {}
      )
    }
  },
  methods: {
    loadInitData() {
      this.$wait.start('fetchingDetails')

      Promise.all([this.loadAdminGroups(), this.loadReporterReports()]).then(
        () => {
          this.$wait.end('fetchingDetails')
        }
      )
    },
    loadAdminGroups() {
      return singleClickAPI.groups().then(res => {
        this.groups = res.groups
      })
    },
    loadReporterReports() {
      return selfServeReportsAPI.reporterReports().then(res => {
        this.reporterReports = res.reporter_reports
        this.reporterReports.forEach(rep => {
          rep['showShared'] =
            rep.category !== 'fin_reports' &&
            rep.shared_group_ids &&
            rep.shared_group_ids.length
              ? ''
              : 'd-none text-muted '
          // rep['displayName'] =
          //   rep.category !== 'fin_reports' &&
          //   rep.shared_group_ids &&
          //   rep.shared_group_ids.length
          //     ? rep.name + ' - [User: ' + rep.user_id + ']'
          //     : rep.name
        })
      })
    },
    getClientDefaults() {
      this.$wait.start('loadingClientDefaults')

      singleClickAPI.reportDefaults(this.selectedGroup.id).then(res => {
        this.reportDefaults = res.defaults
        this.prepareReporterData()
        this.$wait.end('loadingClientDefaults')
      })
    },
    prepareReporterData() {
      this.reporterSelectedPeer = this.defaultPeerOptions.find(
        po => po.id === this.reporterPeer.value
      )
      this.reporterSelectedBankReport = this.bankNavigatorReports.find(
        bnr => bnr.id === parseInt(this.reporterBankReport.value)
      )
      this.reporterSelectedCUReport = this.cuNavigatorReports.find(
        cnr => cnr.id === parseInt(this.reporterCUReport.value)
      )
      this.reporterSelectedBHCReport = this.bhcNavigatorReports.find(
        bnr => bnr.id === parseInt(this.reporterBHCReport.value)
      )
    },
    updateDefault(option, reportAttr, reportType) {
      if (this.selectedGroup) {
        singleClickAPI
          .updateDefault(
            this.selectedGroup.id,
            reportType,
            reportAttr,
            option.id
          )
          .then(() => {
            this.$toasted.global.action_success('Default changes updated.')
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-title {
  font-weight: 600;
}
</style>

<style lang="scss"></style>
