import axios from 'axios'

export default {
  groups() {
    return axios
      .get('/api/admin/single_click_defaults/groups', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  reportDefaults(groupID) {
    return axios
      .get('/api/admin/single_click_defaults/report_defaults', {
        params: {
          group_id: groupID
        },
        handleErrors: false
      })
      .then(res => {
        return res.data
      })
  },
  updateDefault(groupID, type, attr, val) {
    return axios
      .put(
        '/api/admin/single_click_defaults/update_default',
        {
          group_id: groupID,
          report_type: type,
          default_attr: attr,
          value: val
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  },
  defaultReport(groupID, fiID, reportType) {
    return axios
      .get('/api/admin/single_click_defaults/default_report', {
        params: {
          fi_id: fiID,
          group_id: groupID,
          report_type: reportType
        },
        handleErrors: false
      })
      .then(res => {
        return res.data
      })
  }
}
