var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-page-card',{attrs:{"pageName":"Single Click Defaults","iconClass":"fa fa-sliders fa-lg"}},[_c('div',{staticClass:"single-click-default"},[_c('v-wait',{attrs:{"for":"fetchingDetails"}},[_c('template',{slot:"waiting"},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":20}})],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-group',{attrs:{"label":"Group","label-for":"group","label-cols":3,"horizontal":true}},[_c('multiselect',{attrs:{"options":_vm.groups,"multiple":false,"close-on-select":true,"showLabels":false,"track-by":"id","label":"name","placeholder":"Select Group"},on:{"close":_vm.getClientDefaults},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}})],1)],1)]),(_vm.selectedGroup)?_c('div',{class:['py-3']},[_c('b-tabs',{model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',[_c('template',{slot:"title"},[_c('h6',{staticClass:"tab-title"},[_vm._v("FIN Reporter")])]),_c('v-wait',{attrs:{"for":"loadingClientDefaults"}},[_c('template',{slot:"waiting"},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":20}})],1)],1),_c('div',{staticClass:"pt-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9"},[_c('b-form-group',{attrs:{"label":"Default Peer Group Category","label-for":"peer_group","label-cols":3,"horizontal":true}},[_c('multiselect',{attrs:{"options":_vm.defaultPeerOptions,"multiple":false,"close-on-select":true,"showLabels":false,"track-by":"id","label":"name","placeholder":"Select Peer Category"},on:{"close":function($event){return _vm.updateDefault(
                            _vm.reporterSelectedPeer,
                            'peer_group_type',
                            'fin_reporter'
                          )}},model:{value:(_vm.reporterSelectedPeer),callback:function ($$v) {_vm.reporterSelectedPeer=$$v},expression:"reporterSelectedPeer"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9"},[_c('b-form-group',{attrs:{"label":"Default Bank Report","label-for":"bank_report","label-cols":3,"horizontal":true}},[_c('multiselect',{attrs:{"options":_vm.bankNavigatorReports,"multiple":false,"close-on-select":true,"showLabels":false,"track-by":"id","label":"name","placeholder":"Select Bank Report"},on:{"close":function($event){return _vm.updateDefault(
                            _vm.reporterSelectedBankReport,
                            'bank_report_id',
                            'fin_reporter'
                          )}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(props.option.name)+" ")]),_c('span',{class:props.option.showShared},[_c('b-badge',{staticClass:"px-1",attrs:{"pill":"","variant":"secondary"}},[_vm._v("Shared By User: "+_vm._s(props.option.user_id))])],1)])]}},{key:"option",fn:function(props){return [_c('span',[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(props.option.name)+" ")]),_c('span',{class:props.option.showShared},[_c('b-badge',{staticClass:"px-1",attrs:{"pill":"","variant":"secondary"}},[_vm._v("Shared By User: "+_vm._s(props.option.user_id))])],1)])]}}],null,false,4260875030),model:{value:(_vm.reporterSelectedBankReport),callback:function ($$v) {_vm.reporterSelectedBankReport=$$v},expression:"reporterSelectedBankReport"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9"},[_c('b-form-group',{attrs:{"label":"Default CU Report","label-for":"cu_report","label-cols":3,"horizontal":true}},[_c('multiselect',{attrs:{"options":_vm.cuNavigatorReports,"multiple":false,"close-on-select":true,"showLabels":false,"track-by":"id","label":"name","placeholder":"Select CU Report"},on:{"close":function($event){return _vm.updateDefault(
                            _vm.reporterSelectedCUReport,
                            'credit_union_report_id',
                            'fin_reporter'
                          )}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(props.option.name)+" ")]),_c('span',{class:props.option.showShared},[_c('b-badge',{staticClass:"px-1",attrs:{"pill":"","variant":"secondary"}},[_vm._v("Shared By User: "+_vm._s(props.option.user_id))])],1)])]}},{key:"option",fn:function(props){return [_c('span',[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(props.option.name)+" ")]),_c('span',{class:props.option.showShared},[_c('b-badge',{staticClass:"px-1",attrs:{"pill":"","variant":"secondary"}},[_vm._v("Shared By User: "+_vm._s(props.option.user_id))])],1)])]}}],null,false,4260875030),model:{value:(_vm.reporterSelectedCUReport),callback:function ($$v) {_vm.reporterSelectedCUReport=$$v},expression:"reporterSelectedCUReport"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9"},[_c('b-form-group',{attrs:{"label":"Default BHC Report","label-for":"bhc_report","label-cols":3,"horizontal":true}},[_c('multiselect',{attrs:{"options":_vm.bhcNavigatorReports,"multiple":false,"close-on-select":true,"showLabels":false,"track-by":"id","label":"name","placeholder":"Select BHC Report"},on:{"close":function($event){return _vm.updateDefault(
                            _vm.reporterSelectedBHCReport,
                            'bhc_report_id',
                            'fin_reporter'
                          )}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(props.option.name)+" ")]),_c('span',{class:props.option.showShared},[_c('b-badge',{staticClass:"px-1",attrs:{"pill":"","variant":"secondary"}},[_vm._v("Shared By User: "+_vm._s(props.option.user_id))])],1)])]}},{key:"option",fn:function(props){return [_c('span',[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(props.option.name)+" ")]),_c('span',{class:props.option.showShared},[_c('b-badge',{staticClass:"px-1",attrs:{"pill":"","variant":"secondary"}},[_vm._v("Shared By User: "+_vm._s(props.option.user_id))])],1)])]}}],null,false,4260875030),model:{value:(_vm.reporterSelectedBHCReport),callback:function ($$v) {_vm.reporterSelectedBHCReport=$$v},expression:"reporterSelectedBHCReport"}})],1)],1)])])],2)],2)],1)],1):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }